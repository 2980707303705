@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  background: url(../images/common/body_bg.jpg);
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 150px;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 100px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_left {
  width: 24%;
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: 100% !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 834px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 640px) {
  .hed_right {
    bottom: 40px;
  }
}

.hed_center {
  width: 50%;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .hed_center {
    width: 60%;
  }
}

@media only screen and (max-width: 834px) {
  .hed_center {
    display: none;
  }
}

#header {
  width: 100%;
  background: #fff;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #header .hedwrap {
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .hedwrap {
    display: block;
    padding: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .hedwrap {
    align-items: center;
  }
}

#header #siteID {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: right;
  }
}

#header #siteID a {
  opacity: 1;
}

#header .tel2 {
  font-size: 26px;
  background: #e8ece9;
  padding: 31px 50px;
  color: #333333;
  font-family: "Times New Roman";
  display: flex;
  align-items: center;
  width: 345px;
}

#header .tel2 img {
  margin-right: 15px;
}

#header .tel2 a {
  color: #333333;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #header .tel2 {
    font-size: 20px;
    width: 290px;
  }
  #header .tel2 img {
    width: 20% !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #header .tel2 {
    width: 100% !important;
    justify-content: center;
  }
}

@media only screen and (max-width: 834px) {
  #header .tel2 {
    width: 100% !important;
    justify-content: center;
  }
}

#hed_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hed_info {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info {
    display: block;
  }
}

#hed_info .time {
  font-size: 14px;
  text-align: left;
  margin-right: 15px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .time {
    text-align: right;
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #hed_info .time {
    margin-right: 0;
  }
}

#hed_info .cont i {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #hed_info .cont i {
    margin-right: 0;
  }
}

#hed_info .cont a {
  padding: 15px;
  display: block;
  background: #aa4a92;
  border-radius: 50px;
  font-size: 22px;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #hed_info .cont a span {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .cont a span {
    display: none;
  }
}

#hed_info .hed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hed_info .hed_cont {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .hed_cont {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global {
    padding-top: 50px;
  }
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

#nav_global ul li:nth-of-type(1) {
  display: none;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li:nth-of-type(1) {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: left;
  }
}

#nav_global ul li a {
  color: #333333;
  display: block;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 15px;
}

#nav_global ul li a span {
  font-size: 10px;
  color: #aeb0ad;
  font-family: 'Sorts Mill Goudy', serif;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li a span {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #nav_global ul li a {
    padding: 5px;
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global ul li a {
    border-bottom: 1px solid #f3f3f3;
  }
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

.mainArea {
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mainArea img {
    width: 120% !important;
    min-height: 60vw !important;
    margin: 0 -36% !important;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainArea img {
    width: 60% !important;
    min-height: 32vw !important;
    margin: 0 -14% !important;
    object-fit: cover;
  }
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1334px;
  min-width: 240px;
  height: 350px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 3.1em;
  color: #333;
  line-height: 1.6em;
  text-align: left;
  background: rgba(222, 223, 218, 0.8);
  padding: 70px;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .mainwrap .main_txt {
    font-size: 2.1em;
    height: 280px !important;
    padding: 30px;
    width: 960px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .mainwrap .main_txt {
    font-size: 2.1em;
    padding: 30px;
    width: 960px;
  }
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    font-size: 1.8em;
    width: 460px;
    padding: 30px;
    height: 280px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    font-size: 1.8em;
    width: 535px;
    padding: 30px;
    height: 280px;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  text-align: center;
  font-family: 'Sorts Mill Goudy', serif;
  background: url(../images/common/main1.jpg) center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 150px 0;
  background: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding: 50px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: #b94047 !important;
  position: fixed !important;
  z-index: 8 !important;
}

.mean-container .mean-nav {
  background: #b94047 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.drawer-hamburger {
  z-index: 101 !important;
}

.drawer-hamburger span {
  background: #333 !important;
}

.drawer-hamburger span:before, .drawer-hamburger span:after {
  background: #333 !important;
}

.drawer-hamburger.change-color span {
  background: #2e4121 !important;
}

.drawer-hamburger.change-color span:before, .drawer-hamburger.change-color span:after {
  background: #2e4121 !important;
}

.drawer-nav {
  z-index: 101 !important;
  background: url(../images/shiba/green_bg.png);
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 20px;
}

.drawer-nav ul li:after {
  content: "";
  width: 32px;
  height: 1px;
  display: block;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
}

.drawer-hamburger {
  z-index: 201 !important;
}

.drawer-overlay {
  z-index: 201 !important;
}

.drawer-nav {
  z-index: 202 !important;
}

.drawer-open .drawer-hamburger .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .for-pc {
    display: none !important;
  }
}

.not-for-pc {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .not-for-pc {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .not-for-sp {
    display: none !important;
  }
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #333333;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  footer#global_footer {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  footer#global_footer {
    margin-bottom: 90px;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li a {
  color: #333333;
  font-family: 'Sorts Mill Goudy', serif;
  text-decoration: none;
  padding: 16px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.address {
  margin-top: 20px;
}

.pagetop {
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 2;
}

.pagetop a {
  padding: 20px 24px;
  border-radius: 50%;
  background: #cbd0d3;
  font-size: 20px;
  display: block;
  color: #ffffff;
}

.ftlogo {
  margin-top: 50px;
  font-size: 24px;
}

.small_bg {
  padding: 10px 0;
  background: #272f4f;
  color: #fff;
}

.small_bg a {
  color: #fff;
}

.ft_nav {
  margin-bottom: 50px;
}

#footer {
  padding: 0;
}

#footer .tel2 {
  margin-bottom: 50px;
}

#footer .tel2 a {
  color: #333333;
}

.footer2 {
  background: #1955a6;
  padding: 50px 0;
}

.footer2 .fth2 h2 {
  font-size: 30px;
  color: #ffffff;
  font-family: 'Sorts Mill Goudy', serif;
}

.footer2 .fth2 span {
  font-size: 40px;
  color: #b5b5ae;
}

.footer2 .fttxt {
  color: #ffffff;
  margin: 50px 0 30px;
}

.footer2 .ftwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap {
    display: block;
    padding: 15px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .ftwrap {
    padding: 15px;
  }
}

.footer2 .tel2 {
  width: 400px;
  font-size: 26px;
  border: 1px solid #fff;
  padding: 20px 50px;
  color: #ffffff;
  margin-right: 30px;
}

.footer2 .tel2:hover {
  background: #b5b5ae;
  opacity: 1;
}

.footer2 .tel2 a {
  color: #ffffff;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .tel2 {
    width: 210px;
    padding: 20px 0;
    font-size: 18px;
  }
  .footer2 .tel2 a {
    width: 210px;
    padding: 20px 0;
    font-size: 18px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .footer2 .tel2 {
    width: 260px;
    padding: 20px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 640px) {
  .footer2 .tel2 {
    width: 100% !important;
  }
}

.footer2 .ftcont a {
  width: 400px;
  font-size: 26px;
  border: 1px solid #fff;
  padding: 20px 50px;
  color: #ffffff;
  font-family: 'Sorts Mill Goudy', serif;
  display: block;
  margin-right: 30px;
}

.footer2 .ftcont a:hover {
  background: #b5b5ae;
  opacity: 1;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .ftcont a {
    width: 190px;
    padding: 20px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .footer2 .ftcont a {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .footer2 .ftcont a {
    width: 260px;
    padding: 20px;
  }
}

.footer2 .ftcont a i {
  margin-left: 30px;
}

.footer2 .shop a {
  width: 400px;
  font-size: 26px;
  border: 1px solid #fff;
  padding: 20px 50px;
  color: #ffffff;
  font-family: 'Sorts Mill Goudy', serif;
  display: block;
}

.footer2 .shop a:hover {
  background: #b5b5ae;
  opacity: 1;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .shop a {
    width: 210px;
    padding: 20px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .footer2 .shop a {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .footer2 .shop a {
    width: 260px;
    padding: 20px;
  }
}

.footer2 .shop a i {
  margin-left: 30px;
}

/* box */
.h2_01 h2 {
  font-size: 1.9em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.7em;
  }
}

.h2_01 span {
  font-size: 0.8em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.h2_02 h2 {
  font-size: 1.9em;
  color: #1955a6;
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.7em;
  }
}

.h2_02 span {
  font-size: 1.5em;
  color: #272f4f;
}

@media only screen and (max-width: 834px) {
  .h2_02 span {
    font-size: 1.5em;
  }
}

.h2_03 h2 {
  font-size: 1.9em;
  color: #1955a6;
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.7em;
  }
}

.h2_03 span {
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  .h2_03 span {
    font-size: 1.5em;
  }
}

.h3_01 h3 {
  font-size: 1.4em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 2px solid #dcdcdc;
  padding: 10px;
}

.h3_02 h3 {
  font-size: 1.6em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: #F3F3F3;
  padding: 15px;
}

.h3_02 span {
  font-size: 0.8em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.txt {
  line-height: 2em;
}

.btn a {
  background: #cbd0d3;
  color: #fff;
  font-family: 'Sorts Mill Goudy', serif;
  font-size: 20px;
  padding: 15px;
  display: block;
  width: 250px;
}

.btn a i {
  margin-left: 15px;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sec_01 .btn a {
  background: url(../images/common/btn_bg.png);
  color: #1955a6;
  background-size: cover;
  width: 292px;
  height: 60px;
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
    justify-content: center !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_01 .flxL {
  width: 48%;
}

.sec_01 .flxR {
  width: 48%;
}

.sec_01 .flxR .txt br:nth-of-type(4) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .sec_01 .flxR .txt br:nth-of-type(4) {
    display: block;
  }
}

.sec_01 .flxR .txt br:nth-of-type(1) {
  display: none;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxR .txt br:nth-of-type(1) {
    display: block;
  }
}

.sec_01 .flxR .txt br:nth-of-type(3) {
  display: none;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxR .txt br:nth-of-type(3) {
    display: block;
  }
}

.sec_01 .flxR .txt br:nth-of-type(6) {
  display: none;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxR .txt br:nth-of-type(6) {
    display: block;
  }
}

.img_table td {
  padding: 0 !important;
}

.img_table .td_T {
  border: none !important;
  padding-bottom: 10px !important;
}

.img_table .td_T img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .img_table .td_T {
    text-align: right !important;
  }
}

.img_table .td_B {
  border: none !important;
}

.img_table .td_B img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .img_table .td_B {
    text-align: right !important;
  }
}

.img_table .td_L {
  border: none !important;
  padding-right: 20px !important;
}

.img_table .td_L img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: flex-start;
  }
}

.instagram div.sns_list > div {
  width: 20% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 48% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 33% !important;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 240px !important;
  height: 240px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 100% !important;
    width: 100% !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  position: relative;
  width: 49%;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    height: 200px;
  }
}

.bnrbox .box span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 200px;
  min-width: 60px;
  height: 25px;
  color: #ffffff;
  font-size: 30px;
  font-family: 'Sorts Mill Goudy', serif;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .bnrbox .box span {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 640px) {
  .bnrbox .box span {
    height: 40px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box span {
    width: 140px;
    font-size: 1.3em;
  }
}

.bnrbox .box h3 {
  display: none;
}

.bnrbox2 {
  justify-content: space-between;
}

.bnrbox2 .box {
  position: relative;
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box {
    width: 100%;
    height: 200px;
  }
  .bnrbox2 .box:nth-of-type(2) {
    margin-top: 30px;
  }
  .bnrbox2 .box:nth-of-type(3) {
    margin-top: 30px;
  }
}

.bnrbox2 .box span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 175px;
  min-width: 60px;
  height: 25px;
  color: #ffffff;
  font-size: 30px;
  font-family: 'Sorts Mill Goudy', serif;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .bnrbox2 .box span {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox2 .box span {
    width: 125px;
    font-size: 1.3em;
  }
}

.bnrbox2 h3 {
  display: none;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  width: 48%;
}

.sec_02 .flxR {
  width: 48%;
}

.newswrap {
  background: #fff;
  padding: 35px;
}

@media only screen and (max-width: 834px) {
  .newswrap {
    margin: 0 10px;
  }
}

.newswrap .news {
  height: 300px;
  overflow-y: scroll;
}

.newswrap .news dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
}

.newswrap .news dd {
  width: 100% !important;
  border: none !important;
}

.newswrap .news dl {
  display: block !important;
  border-bottom: 1px dashed #dedfda !important;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.txt {
  line-height: 2em;
}

.h2_04 h2 {
  font-size: 1.9em;
  color: #333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 1.7em;
  }
}

.conceptwrap {
  background: #e8ece9;
  padding: 30px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.conceptwrap .txt {
  font-size: 18px;
}

.rec_tel {
  font-size: 26px;
}

.rec_tel a {
  color: #333333;
}

.rec_tel div > span {
  margin-left: 30px;
  font-size: 20px;
}

.table_01 dt {
  background: #e8ece9 !important;
  border: none !important;
}

.table_01 dd {
  border: none !important;
}

.table_01 dl {
  border-bottom: 1px solid #B5B5AE !important;
}

.table_01 dl:last-child {
  border: none !important;
}

.table_02 .td_top {
  background: #e8ece9 !important;
}

.table_02 td {
  border: none !important;
}

.table_02 dd {
  border: none !important;
}

.table_02 tr {
  border-bottom: 1px solid #B5B5AE !important;
}

.table_03 tr:nth-of-type(1) .td_L {
  background: #4c6cb3 !important;
  color: #ffffff;
}

.table_03 tr:nth-of-type(2) .td_L {
  background: #8f9ab3 !important;
  color: #ffffff;
}

.table_03 tr:nth-of-type(3) .td_L {
  background: #6b82b3 !important;
  color: #ffffff;
}

.table_03 tr:nth-of-type(4) .td_L {
  background: #4769b3 !important;
  color: #ffffff;
}

.table_03 tr:nth-of-type(5) .td_L {
  background: #2451b3 !important;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .table_03 .td_L {
    height: auto !important;
  }
}

.table_03 .td_L span {
  display: block;
  font-size: 13px;
}

.table_03 td {
  border: none !important;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .txt {
    width: 100% !important;
  }
  .sec_03 .img {
    width: 100% !important;
  }
}

.sec_03 .txt {
  width: 48%;
}

.sec_03 .img {
  width: 48%;
}

.sec_03 > div:first-child {
  display: none;
}

.sec_03 > div:last-child {
  display: none;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .txt {
    width: 100% !important;
  }
  .sec_04 .img {
    width: 100% !important;
  }
}

.sec_04 .txt {
  width: 55%;
}

.sec_04 .img {
  width: 43%;
}

.sec_04 > div:first-child {
  display: none;
}

.sec_04 > div:last-child {
  display: none;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  background: #e8ece9;
  padding: 30px;
}

.sec_05 .btn a {
  background: url(../images/common/btn_bg.png);
  color: #1955a6;
  background-size: cover;
  width: 292px;
  height: 60px;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .flxL {
    width: 100% !important;
    justify-content: center !important;
  }
  .sec_05 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_05 .flxL {
  width: 48%;
}

.sec_05 .flxR {
  width: 48%;
}

.sec_06 {
  background: #e8ece9;
  padding: 30px;
}

.sec_06 > div:first-child {
  display: none;
}

.sec_06 > div:last-child {
  display: none;
}

.sec_08 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.sec_08 .btn a {
  background: url(../images/common/btn_bg.png);
  color: #1955a6;
  background-size: cover;
  width: 292px;
  height: 60px;
}

@media only screen and (max-width: 834px) {
  .sec_08 {
    display: block;
  }
  .sec_08 .flxL {
    width: 100% !important;
    justify-content: center !important;
  }
  .sec_08 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_08 .flxL {
  width: 48%;
}

.sec_08 .flxR {
  width: 48%;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 60px solid #d4d9dc;
  margin-top: 50px;
}

.imgbox {
  justify-content: flex-start;
}

.imgbox .box {
  width: 33%;
  text-align: center;
}

.imgbox .box img {
  width: 90% !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.imgbox .box h3 {
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid #dedfda;
  margin: 5px;
}

.imgbox .box article > div {
  font-size: 14px;
  text-align: left;
  padding: 10px;
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.imgbox_L {
  justify-content: flex-start;
}

.imgbox_L .box {
  width: 33%;
  text-align: center;
}

.imgbox_L .box:last-of-type article > div {
  display: none;
}

.imgbox_L .box:nth-last-of-type(2) article > div {
  display: none;
}

.imgbox_L .box img {
  width: 90% !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.imgbox_L .box h3 {
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid #dedfda;
  margin: 5px;
}

.imgbox_L .box article > div {
  font-size: 14px;
  text-align: left;
  padding: 10px;
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .imgbox_L .box {
    width: 100%;
    margin-top: 20px;
  }
}

.gmap iframe {
  width: 100%;
}

.menu_table dt {
  border: none !important;
  font-size: 1.2em;
}

@media only screen and (max-width: 834px) {
  .menu_table dt {
    display: block !important;
    width: 100% !important;
  }
}

.menu_table dd {
  border: none !important;
  text-align: right;
  font-size: 1.2em;
}

@media only screen and (max-width: 834px) {
  .menu_table dd {
    width: 100% !important;
  }
}

.menu_table dl {
  border-bottom: 1px solid #cbd0d3 !important;
}

@media only screen and (max-width: 834px) {
  .menu_table dl {
    display: block !important;
  }
}

.menu_table span {
  font-size: 14px;
}

.rinen_txt .txt {
  font-size: 26px;
  font-weight: bold;
  width: 1200px;
  text-align: right !important;
}

.style_form th {
  background: #ffffe0;
  padding: 10px;
}

.style_form td {
  padding: 16px;
}

.midashi h2 {
  color: #fff;
  line-height: 2em;
  font-size: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.blogwrap {
  background: #fff;
  padding: 35px;
}

@media only screen and (max-width: 834px) {
  .blogwrap {
    margin: 0 10px;
  }
}

.Blog {
  height: 300px;
  overflow-y: scroll;
}

.Blog .blog_photo {
  display: none;
}

.Blog .detail {
  display: none;
}

.Blog div.blog_list {
  border: none;
}

.Blog .blog_text {
  font-size: 1em !important;
}

.Blog .blog_date {
  color: #333333 !important;
}

.Blog h3 a {
  color: #333333 !important;
}

.bg_100per_wrap {
  position: relative;
  padding: 290px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 530px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 530px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #dedfda;
  background-size: cover;
  z-index: 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.bg_100per_wrap2 {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #000;
}

#TRANS_ANNOT {
  display: none;
}

#builingual {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999999;
  padding: 5px 10px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.switch {
  float: right;
  width: 33px;
  margin-left: -15px;
}

.switch input[type="checkbox"] {
  display: none;
}

.switch label {
  display: block;
  box-sizing: border-box;
  padding: 5px 0 8px 0;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(136, 136, 136, 0.4);
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  transition: .3s;
}

/* ON */
.switch label span:after {
  content: "OFF";
  font-size: 10px;
  font-weight: 500;
  color: #aaa;
}

.switch #cmn-toggle-1:checked + label {
  background-color: #ffca29;
  box-shadow: none;
}

.switch #cmn-toggle-1:checked + label span:after {
  content: "ON";
  color: #fff;
}

.bili_name dl:nth-of-type(2) dd div,
.bili_name dl:last-of-type dd div {
  display: block !important;
}

.bili_name dl:nth-of-type(2) dd .translate,
.bili_name dl:last-of-type dd .translate {
  display: none !important;
}

@media screen and (max-width: 834px) {
  .switch {
    float: right;
    order: 1;
    padding-top: 0px !important;
  }
  #builingual {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 10px !important;
    background: rgba(255, 255, 255, 0.4) !important;
  }
  #builingual .language {
    padding: 0 20px 0 0 !important;
    font-size: 13px;
  }
  #builingual .language select {
    margin: 5px !important;
    padding: 3px !important;
  }
}

@media screen and (max-width: 640px) {
  #builingual {
    justify-content: center;
    width: 100%;
    padding: 0px 5px !important;
    background: rgba(255, 255, 255, 0.9) !important;
  }
}
